<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalCreateOrUpdateNameDocument"
      @click:outside="executeCloseModalCreateOrUpdateNameDocument"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            {{ currentNameDocument ? "Actualizar" : "Crear" }} Nombre de
            Documento
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-form @submit.prevent="sendUpdateOrCreateNameDocument">
              <v-row align-content="center" class="mt-2">
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="name">
                    <b> Nombre del Documento * </b>
                  </label>
                  <v-text-field
                    :placeholder="`Ingresa el Nombre del Documento`"
                    v-model="$v.form.name.$model"
                    :error="$v.form.name.$invalid && submitUpload"
                    :error-messages="
                      $v.form.name.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label class="mt-1">
                    <b> Área Responsable * </b>
                  </label>
                  <v-select
                    placeholder="Selecciona el Área Responsable"
                    v-model="form.responsible_area"
                    :items="assignedAreas"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-0"
                  v-if="!currentNameDocument"
                >
                  <label class="mt-1">
                    <b> Unidad de Negocio * </b>
                  </label>
                  <v-autocomplete
                    placeholder="Selecciona la Unidad de Negocio"
                    @change="searchFoldersDocuments(null)"
                    v-model="form.business_unit"
                    :items="activeBusinessUnits"
                    item-text="name"
                    item-value="code"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-0"
                  v-if="!currentNameDocument"
                >
                  <label class="mt-1">
                    <b> Carpeta Contenedora * </b>
                  </label>
                  <v-autocomplete
                    placeholder="Selecciona la Carpeta Contenedora"
                    v-model="$v.form.folder_id.$model"
                    :disabled="loadingFolders || !form.business_unit"
                    @update:search-input="searchFoldersDocuments"
                    :items="foldersDocumentsFromBusinessUnit.items"
                    item-text="name"
                    item-value="id"
                    :loading="loadingFolders"
                    no-data-text="Sin Carpetas Encontradas"
                    :error="$v.form.folder_id.$invalid && submitUpload"
                    :error-messages="
                      $v.form.folder_id.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  >
                    <template v-slot:item="data">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <v-chip
                            small
                            class="mt-1"
                            :color="data.item.parent ? 'success' : 'primary'"
                          >
                            {{ !data.item.parent ? "Carpeta" : "Subcarpeta" }}
                          </v-chip>
                        </div>
                      </v-list-item-action>
                    </template>
                    <template v-slot:selection="data">
                      <div
                        class="w-100 d-flex align-items-center justify-content-between"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div
                            class="d-flex align-items-center justify-content-end"
                          >
                            <v-chip
                              class="mt-1"
                              small
                              :color="data.item.parent ? 'success' : 'primary'"
                            >
                              {{ !data.item.parent ? "Carpeta" : "Subcarpeta" }}
                            </v-chip>
                          </div>
                        </v-list-item-action>
                      </div>
                    </template>
                  </v-autocomplete>
                  <small class="text-primary" v-if="!form.business_unit">
                    <b>IMPORTANTE: </b>
                    Selecciona la Unidad de Negocio para buscar las Carpetas
                  </small>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class=""
                  v-if="!currentNameDocument"
                >
                  <label class="mt-1">
                    <b> Obligatorio</b>
                  </label>
                  <v-checkbox
                    class="mt-0"
                    v-model="form.required"
                    :true-value="true"
                    :false-value="false"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalCreateOrUpdateNameDocument"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            @click="sendUpdateOrCreateNameDocument"
            :color="`${currentNameDocument ? 'warning' : 'primary'} text-white`"
            small
            class="float-right"
          >
            {{ currentNameDocument ? "Actualizar" : "Crear" }} Nombre de
            Documento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
import { ACTIVE_BUSINESS_UNITS } from "@/constants/presale";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateOrUpdateNameDocument: {
      type: Function,
      default: () => {},
    },
    currentNameDocument: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      loadingFolders: false,
      debounce: null,
      debounceTime: 500,
      form: {
        name: null,
        responsible_area: null,
        folder_id: null,
        business_unit: null,
        required,
      },
      submitUpload: false,
      responsibleAreas: [],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        business_unit: {},
        responsible_area: { required },
        folder_id: this.currentNameDocument ? {} : { required },
        required: {},
      },
    };
  },
  mounted() {
    this.getAssignedAreas();
  },
  methods: {
    ...mapActions({
      updateOrCreateNameDocument: "document/updateOrCreateNameDocument",
      associateNameDocument: "document/associateNameDocument",
      getAllfoldersDocuments: "document/getAllfoldersDocuments",
      getAssignedAreas: "document/getAssignedAreas",
    }),
    async searchFoldersDocuments(search = null) {
      if (
        !search ||
        ((search || typeof search == "string") && !this.form.folder_id)
      ) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = await setTimeout(async function () {
          self.form.folder_id = null;
          self.loadingFolders = true;
          await self.getAllfoldersDocuments({
            filter_from_business_unit: true,
            business_unit_code: self.form.business_unit,
            filter: search ? search : undefined,
            items: 20,
          });
          self.loadingFolders = false;
        }, self.debounceTime);
      }
    },
    async sendUpdateOrCreateNameDocument() {
      let self = this;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        self.loading = true;
        let payload = {
          id: self.currentNameDocument
            ? self.currentNameDocument.id
            : undefined,
          name: self.form.name,
          assigned_area_id: self.form.responsible_area,
          status: self.form.required ? true : false,
          created_by: self.user.email,
          updated_by: self.currentNameDocument ? self.user.email : undefined,
        };
        const resp = await self.updateOrCreateNameDocument(payload);
        if (resp.status == 200) {
          if (!self.currentNameDocument) {
            await self.associateNameDocument({
              folder_id: self.form.folder_id,
              document_type_id: resp.data.id,
              required: self.form.required ? true : false,
              created_by: self.user.email,
            });
          }
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: self.currentNameDocument
              ? `Nombre de Documento actualizado correctamente`
              : `Nombre de Documento creado correctamente`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.loading = false;
          this.$emit("nameDocumentCreatedOrUpdated");
          this.closeModalCreateOrUpdateNameDocument(true);
          this.resetForm();
          return;
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalCreateOrUpdateNameDocument($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalCreateOrUpdateNameDocument($event);
      }
    },
    setNameDocument(nameDocument) {
      this.form = {
        name: nameDocument.name,
        responsible_area: this.assignedAreas.find(
          (area) => area.name == nameDocument.assigned_area.name
        ).id,
      };
    },
    resetForm() {
      this.form = {
        name: null,
        business_unit: null,
        responsible_area: null,
        folder_id: null,
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      businessUnits: "document/businessUnits",
      foldersDocumentsFromBusinessUnit:
        "document/foldersDocumentsFromBusinessUnit",
      assignedAreas: "document/assignedAreas",
    }),
    activeBusinessUnits() {
      return this.businessUnits.filter((item) =>
        ACTIVE_BUSINESS_UNITS.includes(item.name)
      );
    },
  },
  watch: {
    currentNameDocument(newForm) {
      if (newForm) {
        this.setNameDocument(newForm);
      } else {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
