<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalCreateOrUpdateFolder"
      @click:outside="executeCloseModalCreateOrUpdateFolder"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            {{ currentFolder ? "Actualizar" : "Crear" }} {{ getFolderType }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="">
            <v-form @submit.prevent="sendUpdateOrCreateFolder">
              <v-row align-content="center" class="mt-2">
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="pt-0 mb-2"
                  v-if="parentFolder"
                >
                  <b> Carpeta Contenedora: </b>
                  <p class="mt-5">
                    {{ parentFolder.name }}
                  </p>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="name_folder">
                    <b> Nombre de la {{ getFolderType }} * </b>
                  </label>
                  <v-text-field
                    :placeholder="`Ingresa el Nombre de la ${getFolderType}`"
                    v-model="$v.form.name_folder.$model"
                    :error="$v.form.name_folder.$invalid && submitUpload"
                    :error-messages="
                      $v.form.name_folder.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label class="mt-1" v-if="!currentFolder && !parentFolder">
                    <b> Unidad de Negocio * </b>
                  </label>
                  <v-select
                    v-if="!currentFolder && !parentFolder"
                    placeholder="Selecciona la Unidad de Negocio"
                    v-model="$v.form.business_unit.$model"
                    :disabled="
                      parentFolder || (currentFolder && currentFolder.parent)
                        ? true
                        : false
                    "
                    :error="$v.form.business_unit.$invalid && submitUpload"
                    :error-messages="
                      $v.form.business_unit.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    :items="activeBusinessUnits"
                    item-text="name"
                    item-value="code"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalCreateOrUpdateFolder"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            @click="sendUpdateOrCreateFolder"
            :color="`${currentFolder ? 'warning' : 'primary'} text-white`"
            small
            class="float-right"
          >
            {{ currentFolder ? "Actualizar" : "Crear" }} {{ getFolderType }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
import { ACTIVE_BUSINESS_UNITS } from "@/constants/presale";
import roles from "@/mixins/roles";

export default {
  mixins: [roles],
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateOrUpdateFolder: {
      type: Function,
      default: () => {},
    },
    currentFolder: {
      default: null,
    },
    parentFolder: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name_folder: null,
        business_unit: null,
      },
      submitUpload: false,
    };
  },
  validations() {
    return {
      form: {
        name_folder: { required },
        business_unit: {
          required,
        },
      },
    };
  },
  mounted() {
    if (this.currentUserIsSuperAdmin) {
      this.getBusinessUnits();
    } else {
      this.getBusinessUnits({
        user_email: this.user.email,
      });
    }
  },
  methods: {
    ...mapActions({
      updateOrCreateFolder: "document/updateOrCreateFolderDocument",
      getBusinessUnits: "document/getBusinessUnits",
    }),
    async sendUpdateOrCreateFolder() {
      let self = this;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        self.loading = true;
        const resp = await self.updateOrCreateFolder({
          folder_id: self.currentFolder ? self.currentFolder.id : undefined,
          name: self.currentFolder
            ? self.currentFolder.name
            : self.form.name_folder,
          new_name: self.currentFolder ? self.form.name_folder : undefined,
          business_unit_code: self.form.business_unit,
          parent_id: self.parentFolder ? self.parentFolder.id : null,
          created_by: self.currentFolder
            ? self.currentFolder.updated_by
            : self.user.email,
          updated_by: self.currentFolder ? self.user.email : undefined,
        });
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: self.currentFolder
              ? `${self.getFolderType} actualizada correctamente`
              : `${self.getFolderType} creada correctamente`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("folderCreatedOrUpdated");
          this.closeModalCreateOrUpdateFolder(true);
          this.resetForm();
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    executeCloseModalCreateOrUpdateFolder($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalCreateOrUpdateFolder($event);
      }
    },
    setFolder(folder) {
      this.form = {
        name_folder: this.parentFolder ? null : folder.name,
        business_unit: this.parentFolder
          ? this.parentFolder.business_unit.code
          : folder.business_unit.code,
      };
    },
    resetForm() {
      this.form = {
        name_folder: null,
        business_unit: null,
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      businessUnits: "document/businessUnits",
    }),
    getFolderType() {
      return this.parentFolder ? "Subcarpeta" : "Carpeta";
    },
    activeBusinessUnits() {
      return this.businessUnits.filter((item) =>
        ACTIVE_BUSINESS_UNITS.includes(item.name)
      );
    },
  },
  watch: {
    currentFolder(newForm) {
      if (newForm) {
        this.setFolder(newForm);
      } else {
        this.resetForm();
      }
    },
    parentFolder(newForm) {
      if (newForm) {
        this.setFolder(newForm);
      } else {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
